<template>
  <div :class="{ 'my-1': small }">
    <template v-if="tooltipText">
      <v-tooltip top content-class="custom-top-tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            class="mx-2 font-weight-600 text-uppercase justify-center"
            :color="color"
            :text-color="textColor"
            label
            :small="small"
            :class="{ 'd-inline': small }"
          >
            {{ text }}
          </v-chip>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </template>
    <v-chip
      v-else
      class="mx-2 font-weight-600 text-uppercase justify-center"
      :color="color"
      :text-color="textColor"
      label
      :small="small"
      :class="{ 'd-inline': small }"
    >
      {{ text }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    tooltipText: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
